import React from "react";
import clsx from "clsx";

import { useActiveTenant } from "tenants/TenantProvider";

export const TenantLogo = ({ className, ...props }: { className?: string }) => {
	const tenant = useActiveTenant();

	const { assetPath, logoSize } = tenant.investorPortal;

	return (
		<div
			{...props}
			className={clsx(
				className,
				logoSize === "s" && ["w-[100px] md:w-[130px]"],
				logoSize === "m" && ["w-[100px] md:w-[140px]"],
				logoSize === "lg" && ["w-[100px] md:w-[160px]"],
				logoSize === "xl" && ["w-[100px] md:w-[180px]"],
				logoSize === "2xl" && ["w-[100px] md:w-[220px]"],
				"flex justify-start items-center h-[80px]",
			)}
		>
			<img
				src={`/tenants/assets/${assetPath}/logo.png`}
				className="object-contain object-left"
				alt="Tenant Logo"
			/>
		</div>
	);
};
